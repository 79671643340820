exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CookieBar_cookiebar{background-color:var(--cookiebar_BackgroundColor,#f7f7f7)}.CookieBar_content{position:relative;width:100%;padding:5px 10% 5px 5px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;-webkit-flex-wrap:wrap;flex-wrap:wrap;text-align:center;line-height:1.5}.CookieBar_description{color:var(--cookiebar_CookiesPolicy_Color,grey);font-size:var(--cookiebar_CookiesPolicy_Size,13px)}.CookieBar_holder{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-flex-wrap:wrap;flex-wrap:wrap}.CookieBar_btn-wrapper{padding:.1em .5em}.CookieBar_btn-accept-policy,.CookieBar_btn-more-info{vertical-align:bottom}.CookieBar_btn-close{width:10%;height:100%;position:absolute;top:0;right:0;background-color:transparent;font-size:22px;border:none;outline:none;cursor:pointer}.CookieBar_btn-close img{max-width:18px;max-height:18px}.CookieBar_btn-close svg{width:18px;height:18px}.CookieBar_btn-close:focus{outline-offset:-1px}.CookieBar_btn-close:focus .visually-hidden{display:none}.CookieBar_btn-close .btn-cnt{padding-top:0}", ""]);

// exports
exports.locals = {
	"cookiebar": "CookieBar_cookiebar",
	"content": "CookieBar_content",
	"description": "CookieBar_description",
	"holder": "CookieBar_holder",
	"btn-wrapper": "CookieBar_btn-wrapper",
	"btnWrapper": "CookieBar_btn-wrapper",
	"btn-accept-policy": "CookieBar_btn-accept-policy",
	"btnAcceptPolicy": "CookieBar_btn-accept-policy",
	"btn-more-info": "CookieBar_btn-more-info",
	"btnMoreInfo": "CookieBar_btn-more-info",
	"btn-close": "CookieBar_btn-close",
	"btnClose": "CookieBar_btn-close"
};